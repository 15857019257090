import * as React from "react";
import { graphql, Link } from "gatsby";
import { Global, Header, Main } from "../components";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import styled from "@emotion/styled";
import BackgroundImage from "gatsby-background-image";
import { SEO } from "../components/seo";
import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import ImageTitle from "../components/Main/ImageTitle";

const Container = styled("div")`
  height: 100vh;
  width: 100%;
  text-align: center;
`;
const Title = styled(Link)`
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 59px;
  text-align: center;
  color: #ffffff;
  margin: 20px 0 30px;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  z-index: 4;
  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.6;
  }
`;
// markup
const NotFoundPage = ({}: any) => {
  return (
    <Global>
      <ScrollOverPack>
        <Container>
          <Header />
          <ImageTitle />
          <Title to="/">Перейти на главную страницу</Title>
        </Container>
      </ScrollOverPack>
    </Global>
  );
};

export default NotFoundPage;
export const Head = () => <SEO />;
